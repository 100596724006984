import $ from 'jquery';
import HashString from '../../utils/HashString';
import Lang from '../../utils/Language';
import InitializerEventDispatcher from "../../utils/InitializerEvent";
import SvgSpriteExporter from "../../utils/SvgSpriteExporter";

/**
 * To compare items should set class "data-row-compare-height" and data attribute "data-row-compare-height" with value.
 * After that on the element you want to compare it should set class with the same value as the data attribute.
 *
 * It's very important to have equal padding and border size in all elements you want to compare.
 *
 * Compare:
 * ELEMENT class="data-row-compare-height" data-row-compare-height="some-class-name"
 *
 * With:
 * ELEMENT class="some-class-name"
 * ELEMENT class="some-class-name"
 *
 */
const rowCompareHeight = () => {
    const elements = document.getElementsByClassName('data-row-compare-height');

    for (let i = 0; i < elements.length; i+=1) {
        elements[i].removeAttribute('style');

        const className = elements[i].getAttribute('data-row-compare-height');
        let maxHeight = elements[i].clientHeight;
        const compareTo = document.getElementsByClassName(className);

        for (let j = 0; j < compareTo.length; j+=1) {
            if (compareTo[j].offsetWidth !== 0) {
                compareTo[j].removeAttribute('style');

                const compareElementHeight = compareTo[j].clientHeight;

                if (maxHeight < compareElementHeight) {
                    maxHeight = compareElementHeight;
                }
            }
        }

        if (maxHeight !== 0) {
            elements[i].style.height = `${maxHeight}px`;

            for (let x = 0; x < compareTo.length; x+=1) {
                compareTo[x].style.height = `${maxHeight}px`;
            }
        }
    }
};

const updateLinks = (id, action) => {
    const button = document.getElementById(`comparison-button-${id}`);

    let icon = SvgSpriteExporter('icon_tick');
    if (action === 'remove') {
        icon = SvgSpriteExporter('icon_compare');
    }

    if (button) {
        button.title = Lang.t('app_products_compare.notifications.added');
        button.innerHTML = icon;
    }

    const link = document.getElementById(`comparison-link-${id}`);

    if (link) {
        link.title = Lang.t('app_products_compare.notifications.added');
        link.innerHTML = `${icon}<span class="padding-left-1">${Lang.t('app_products_compare.compare')}</span>`;
    }
}

const updateHeaderLabel = (count, url) => {
    const label = document.createElement('span');

    label.addEventListener('click', () => {
        import(/* webpackChunkName: "modal-box" */ '../ModalBox').then(module => {
            module.ModalBox.open(url);
        });
    });
    label.style.cursor = 'pointer';
    label.classList.add('active');
    label.classList.add('main-header-navigation-item-link');
    label.innerHTML = `
        ${SvgSpriteExporter('icon_compare')}
        <span class="main-header-navigation-item-counter">
            ${count}
        </span>`;

    const container = document.getElementById('comparison-header-label');
    container.innerHTML = '';

    if (count > 0) {
        document.getElementById('comparison-header-label').appendChild(label);
    }
}

const initCompare = () => {
    const $body = $('body');

    $body.on('click', '.js-compare-tabs', (e) => {
        import(/* webpackChunkName: "bootstrap-tab" */ 'bootstrap-sass/assets/javascripts/bootstrap/tab').then(
            () => {
                $(e.currentTarget).tab('show');

                InitializerEventDispatcher.dispatchEvent('ContentChanged');

                setTimeout(() => {
                    rowCompareHeight();
                }, 200);
            }
        );
    });

    $body.on('click', '[data-add-compare-product]', (e) => {
        $.ajax({
            type: 'post',
            url: `/${Lang.url}product-catalog/compare/add/${e.currentTarget.dataset.addCompareProduct}`,
            id: e.currentTarget.dataset.addCompareProduct,
            success(data) {
                updateLinks(this.id, 'add');
                updateHeaderLabel(data.data.count, data.data.url)
                import(/* webpackChunkName: "request-response" */ '../../utils/RequestResponse').then(module => {
                    module.default(data);
                });
            },
            error(xhr) {
                import(/* webpackChunkName: "request-response" */ '../../utils/RequestResponse').then(module => {
                    module.default(JSON.parse(xhr.responseText));
                });
            }
        });
    });

    $body.on('click', '[data-remove-compare-product]', (e) => {
        $.ajax({
            type: 'post',
            url: `/${Lang.url}product-catalog/compare/remove/${e.currentTarget.dataset.removeCompareProduct}`,
            id: e.currentTarget.dataset.removeCompareProduct,
            success(data) {
                updateLinks(this.id, 'remove');
                updateHeaderLabel(data.data.count, data.data.url);
                import(/* webpackChunkName: "request-response" */ '../../utils/RequestResponse').then(module => {
                    module.default(data);
                });
            },
            error(xhr) {
                import(/* webpackChunkName: "request-response" */ '../../utils/RequestResponse').then(module => {
                    module.default(JSON.parse(xhr.responseText));
                });
            }
        });
    });

    $body.on('click', '[data-compare-differences]', (e) => {
        const $this = $(e.currentTarget);

        if (parseInt($this.attr('data-compare-differences'), 10) === 1) {
            $this.attr('data-compare-differences', 0);
            $this.text(Lang.t('app_products_compare.show_all'));
            $('.has_no_differences').addClass('element--half-faded element--color-light');
        } else {
            $this.attr('data-compare-differences', 1);
            $this.text(Lang.t('app_products_compare.show_differences'));
            $('.has_no_differences').removeClass('element--half-faded element--color-light');
        }
    });

    // get hash tag from the link and open compare
    const compareHash = HashString.get('compare_products');

    if (compareHash) {
        const compareProducts = JSON.parse(compareHash);

        if (compareProducts.length <= 1) {
            return;
        }

        const promises = [];

        compareProducts.forEach(productId => {
            promises.push(
                new Promise((resolve, reject) => {
                    $.ajax({
                        type: 'post',
                        url: `/${Lang.url}product-catalog/compare/add/${productId}`,
                        success(data) {
                            resolve(data);
                        },
                        error(data) {
                            reject(data);
                        }
                    });
                }).then(data => {
                    import(/* webpackChunkName: "request-response" */ '../../utils/RequestResponse').then(module => {
                        module.default(data);
                    });
                }).catch(data => {
                    import(/* webpackChunkName: "request-response" */ '../../utils/RequestResponse').then(module => {
                        module.default(data);
                    });
                })
            )
        });

        Promise.all(promises).then(() => {
            import(/* webpackChunkName: "modal-box" */ '../ModalBox').then(module => {
                module.ModalBox.open('/product-catalog/compare/list');
            });
        });
    }

    let resizeTimer;

    $(window).on('resize', () => {
        clearTimeout(resizeTimer);

        resizeTimer = setTimeout(() => {
            rowCompareHeight()
        }, 500);
    });

    window.addEventListener('modal-box:show', e => {
        if (e.detail === '/product-catalog/compare/list') {
            // Browser need time to adjust the height of the rows.
            setTimeout(() => {
                rowCompareHeight();
            }, 200);
        }
    });
}

export { initCompare, updateLinks, updateHeaderLabel }