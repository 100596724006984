const HashString = {
    current: {},
    initiated: false,

    set(key, value) {
        if (!this.initiated) {
            this.getHash();
        }

        HashString.current[key] = value;
        HashString.setHash();
    },

    remove(key) {
        if (!this.initiated) {
            this.getHash();
        }

        if (HashString.current[key]) {
            delete HashString.current[key];
            HashString.setHash();
        }
    },

    get(key) {
        if (!this.initiated) {
            this.getHash();
        }

        if (!(key in HashString.current)) {
            return false;
        }
        return HashString.current[key];
    },

    setHash() {
        if (Object.keys(HashString.current).length > 0) {
            try {
                window.location.hash = `!${encodeURIComponent(
                    btoa(JSON.stringify(HashString.current))
                )}`;
            } catch (e) {
            }
        } else {
            window.location.hash = '!';
        }
    },

    getHash() {
        let hash = window.location.hash.substring(2);

        hash = decodeURIComponent(hash);

        if (hash !== '') {
            let base = '';

            try {
                base = atob(hash);
            } catch (e) {
            }

            if (base.length > 5) {
                try {
                    HashString.current = JSON.parse(base);
                } catch (e) {
                    HashString.current = {};
                }
            }
        }

        this.initiated = true;
    }
};

export default HashString;
