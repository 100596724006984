import $ from 'jquery';
import Lang from '../../utils/Language';
import SvgSpriteExporter from "../../utils/SvgSpriteExporter";

const updateLinks = (id, action) => {
    let icon = SvgSpriteExporter('icon_heart_remove');
    let text = Lang.t('users.favorite_products.remove');

    if (action === 'remove') {
        icon = SvgSpriteExporter('icon_heart_add');
        text = Lang.t('users.favorite_products.add');
    }

    const link = document.getElementById(`favorite-product-link-${id}`);
    link.setAttribute('data-remove-favorite-product', id);
    link.removeAttribute('data-add-favorite-product');

    if (action === 'remove') {
        link.setAttribute('data-add-favorite-product', id);
        link.removeAttribute('data-remove-favorite-product');
    }

    if (link) {
        link.innerHTML = `${icon}<span class="padding-left-1">${text}</span>`;
    }
}

const initFavoriteProducts = () => {
    const $body = $('body');

    $body.on('click', '[data-add-favorite-product]', (e) => {
        $.ajax({
            type: 'post',
            url: `/${Lang.url}users/favourite_products/add`,
            data: {
                product_id: e.currentTarget.dataset.addFavoriteProduct
            },
            id: e.currentTarget.dataset.addFavoriteProduct,
            success(data) {
                updateLinks(this.id, 'add');
                import(/* webpackChunkName: "request-response" */ '../../utils/RequestResponse').then(module => {
                    module.default(data);
                });
            },
            error(xhr) {
                import(/* webpackChunkName: "request-response" */ '../../utils/RequestResponse').then(module => {
                    module.default(JSON.parse(xhr.responseText));
                });
            }
        });
    });

    $body.on('click', '[data-remove-favorite-product]', (e) => {
        $.ajax({
            type: 'post',
            url: `/${Lang.url}users/favourite_products/remove`,
            data: {
                product_id: e.currentTarget.dataset.removeFavoriteProduct
            },
            id: e.currentTarget.dataset.removeFavoriteProduct,
            success(data) {
                updateLinks(this.id, 'remove');
                import(/* webpackChunkName: "request-response" */ '../../utils/RequestResponse').then(module => {
                    module.default(data);
                });
            },
            error(xhr) {
                import(/* webpackChunkName: "request-response" */ '../../utils/RequestResponse').then(module => {
                    module.default(JSON.parse(xhr.responseText));
                });
            }
        });
    });
}

export { initFavoriteProducts, updateLinks }